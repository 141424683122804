<script>
// import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
// import Footer from "@/view/front/components/footer";
import IndexService from "@/MainServices/IndexService.js";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
// import Skeleton from "@/view/front/components/skeleton";
import moment from "moment";
import GeneralService from "@/MainServices/GeneralService.js";
/**
 * page-jobs-new component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      auth_full: JSON.parse(localStorage.getItem("user")),
      token: `Bearer ${localStorage.getItem("token")}`,
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      success: false,
      data: [],
      js_profile_name: "",
      js_profession: "",
      js_details: "",
      js_area_of_expertise: null,
      js_interested_in: null,
      js_willing_to_relocate: null,
      js_work_experience: null,
      js_education: null,
      js_skills: null,
      js_certifications: null,
    };
  },
  components: {
    // Navbar,
    Switcher,
    // Skeleton,
    // Footer,
  },
  mounted() {
    this.getInitialData();
  },

  methods: {
    getInitialData() {
      // window.location.href = "/dashboard";
      const id_profile_ch = window.location.pathname.split("/").pop();
      if (
        this.auth_full.role == "Job Seeker" &&
        id_profile_ch != this.auth_full.id
      ) {
        window.location.href = "/dashboard";
      }
      // console.log(
      //   "iddddddddddddddddd",
      //   window.location.pathname.split("/").pop()
      // );
      // console.log("authhhhhhhhhhh", this.auth_full.id);
      const id = this.$route.params.id;
      GeneralService.getSingleJobSeekerProfile(parseInt(id)).then((res) => {
        console.log("Job seeker profile", res.data);
        this.data = res.data;
        this.js_profile_name = res.data.user.name;
        this.js_profession = res.data.profession;
        this.js_details = res.data.details;
        this.js_area_of_expertise = res.data.area_of_expertise;
        this.js_interested_in = res.data.interested_in;
        this.js_willing_to_relocate = res.data.willing_to_relocate;
        this.js_work_experience = res.data.work_experience;
        this.js_education = res.data.education;
        this.js_skills = res.data.skills;
        this.js_certifications = res.data.certificate;
        // let startDate = this.js_work_experience.startingDate
        // let endDate = this.js_work_experience.endDate

        // let old_date_obj = new Date(Date.parse(startDate, "yyyy-mm-dd"));
        // let new_date_obj = new Date(Date.parse(endDate, "yyyy-mm-dd"));
        // var utc2 = Date.UTC(old_date_obj.getFullYear(), old_date_obj.getMonth(), old_date_obj.getDate());
        // var utc1 = Date.UTC(new_date_obj.getFullYear(), new_date_obj.getMonth(), new_date_obj.getDate());
        // this.duration (Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24)));
      });
    },
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>

<template>
  <div>
    <!-- <Navbar /> -->

    <!-- Hero Start -->
    <section class="mt-4">
      <!-- {{data}} -->
      <div
        class="profile_update"
        v-if="!data.current_salary && !data.desired_salary"
      >
        <div class="bg-white text-center pb-5 col-12 px-0">
          <img src="\images/cv_pro/BannerImage.png" class="w-100" alt="" />
          <img
            src="\images/profile_ch.jpg"
            width="150"
            height="150"
            alt=""
            style="margin-top: -88px; border-radius: 50%"
          />
          <h3 class="bold text-muted mt-4" v-if="js_profile_name">
            {{ js_profile_name }}
          </h3>
          <!-- <p>{{ js_area_of_expertise[0] }}</p> -->
          <p class="px-4 my-5" style="font-size: 14px">
            Need to Update Profile
          </p>
          <div class="row border_ch_cv">
            <div class="col-4 one pt-4">
              <h6 class="bold">Area of expertise</h6>
              <p>Need to Update Profile</p>
            </div>
            <div
              class="col-4 p-0 d-flex two pt-4"
              style="justify-content: space-between"
            >
              <img src="\images/cv_pro/border.png" alt="" />
              <div>
                <h6 class="bold">Interested in</h6>
                <p>Need to Update Profile</p>
              </div>
              <img src="\images/cv_pro/border.png" alt="" />
            </div>
            <div class="col-4 three pt-4">
              <h6 class="bold">Willing to relocate</h6>
              <p>Need to Update Profile</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0" v-if="data.current_salary && data.desired_salary">
        <div class="col-9 p-0 cv_col_left_ch row m-0">
          <div class="bg-white text-center pb-5 col-12 px-0">
            <img src="\images/cv_pro/BannerImage.png" class="w-100" alt="" />
            <img
              :src="mediaUrl + data.profile_pic"
              width="150"
              height="150"
              alt=""
              style="margin-top: -88px; border-radius: 50%"
            />
            <h3 class="bold text-muted mt-4" v-if="data.user.name">
              {{ data.user.name }}
            </h3>
            <p v-if="js_area_of_expertise">{{ js_area_of_expertise[0] }}</p>
            <p class="px-4 my-5" style="font-size: 14px">
              {{ js_details }}
            </p>
            <div class="row border_ch_cv">
              <div class="col-4 one pt-4">
                <h6 class="bold">Area of expertise</h6>
                <p v-if="js_area_of_expertise">
                  {{ js_area_of_expertise.toString() }}
                </p>
                <p v-if="!js_area_of_expertise">No Record</p>
                <!-- <span
                  v-for="(_item, _index) in js_area_of_expertise"
                  :key="_index"
                  style="cursor: pointer"
                >
                  {{ _item }}
                </span> -->
              </div>
              <!-- <div class="col-1 p-0">
                <img src="\images/cv_pro/border.png" alt="" />
              </div> -->
              <div
                class="col-4 p-0 d-flex two pt-4"
                style="justify-content: space-between"
              >
                <img src="\images/cv_pro/border.png" alt="" />
                <div>
                  <h6 class="bold">Interested in</h6>
                  <p v-if="js_interested_in">
                    {{ js_interested_in.toString() }}
                  </p>
                  <p v-if="!js_interested_in">No Record</p>
                </div>
                <img src="\images/cv_pro/border.png" alt="" />
              </div>
              <!-- <div class="col-1 p-0">
                <img src="\images/cv_pro/border.png" alt="" />
              </div> -->
              <div class="col-4 three pt-4">
                <h6 class="bold">Willing to relocate</h6>
                <p v-if="js_willing_to_relocate">
                  {{ js_willing_to_relocate.toString() }}
                </p>
                <p v-if="!js_willing_to_relocate">No Record</p>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mt-4">
            <div class="bg-white pb-5 col-12 px-0">
              <div class="py-4 px-4 border_b_silver col-12">
                <span class="card-label font-weight-bolder Main_Blue"
                  >Work experience
                </span>
              </div>
              <div class="col-12 pt-4 row m-0">
                <div class="col-12 p-0 row m-0" v-if="!js_work_experience">
                  No Work Experience
                </div>
                <div class="col-12 p-0 row m-0" v-if="js_work_experience">
                  <div
                    class="row m-0 col-12 p-0"
                    v-for="(item, index) in js_work_experience"
                    :key="index"
                  >
                    <div class="col-4 p-0">
                      <h6 class="m-0">{{ item.companyName }}</h6>
                    </div>
                    <div class="col-4 p-0 pt-2">
                      <p>{{ item.designation }}</p>
                    </div>
                    <div class="col-4 p-0 pt-2">
                      <p>{{ item.startingDate }} - {{ item.endDate }}</p>
                    </div>
                    <div class="col-12 mb-8 mt-2 p-0">
                      <p v-html="item.description">{{ item.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mt-4">
            <div class="bg-white pb-5 col-12 px-0">
              <div class="py-4 px-4 border_b_silver col-12">
                <span class="card-label font-weight-bolder Main_Blue"
                  >Education
                </span>
              </div>
              <div
                class="col-12 pt-4 mb-4"
                v-if="!js_education.university == null"
              >
                No Education
              </div>
              <span v-if="js_education.university == null">
                <div
                  v-for="(item, index) in js_education"
                  :key="index"
                  class="col-12 pt-4 mb-4"
                >
                  <div v-if="!item.university.text">
                    <h6 class="m-0">
                      {{ item.university }}
                    </h6>
                    <span>{{ item.degree }}</span>
                  </div>
                  <div v-if="item.university.text">
                    <h6 class="m-0">
                      {{ item.university.text }}
                    </h6>
                    <span>{{ item.degree.text }}</span>
                  </div>
                  <span>
                    &nbsp;{{ item.startingDate }} -
                    {{ item.endDate }}
                  </span>
                  <p v-html="item.details">{{ item.details }}</p>
                </div>
              </span>
            </div>
          </div>
          <div class="col-12 p-0 mt-4">
            <div class="bg-white pb-5 col-12 px-0">
              <div class="py-4 px-4 border_b_silver col-12">
                <span class="card-label font-weight-bolder Main_Blue"
                  >Skills
                  <!-- {{js_skills}}  -->
                </span>
              </div>
              <div class="col-12 row pt-4">
                <div
                  class="m-0 col-12 p-0 pl-4"
                  v-for="(item, index) in js_skills"
                  :key="index"
                >
                  <div class="col-4 p-0" v-if="item.job_funcation_ch">
                    <h6 class="m-0">{{ item.job_funcation_ch.text }}</h6>
                  </div>
                  <div class="col-4 p-0" v-if="item.job_funcation_ch">
                    <p>{{ item.skill.text }}</p>
                  </div>
                  <div class="col-4 p-0" v-if="item.job_funcation_ch">
                    <p>{{ item.experience }} years</p>
                  </div>
                  <div class="col-12 mb-8 mt-2 p-0"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 mt-4">
            <div class="bg-white pb-5 col-12 px-0">
              <div class="py-4 px-4 border_b_silver col-12">
                <span class="card-label font-weight-bolder Main_Blue"
                  >Certifications
                </span>
              </div>
              <div class="col-12 pt-4" v-if="!js_certifications">
                <h6 class="m-0">No Certificats</h6>
              </div>
              <div class="col-12 row m-0 p-4 pb-0" v-if="js_certifications">
                <div class="row m-0 col-12 p-0">
                  <div class="col-3 pb-0 mb-0 p-0">
                    <h6 class="m-0 pt-2">Certificate Name</h6>
                  </div>
                  <div class="col-3 pb-0 mb-0 mt-2 p-0">
                    <h6 class="m-0">Awarded By</h6>
                  </div>
                  <div class="col-3 pb-0 mb-0 p-0 pt-2">
                    <h6 class="m-0">Starting Date</h6>
                  </div>
                  <div class="col-3 pb-0 mb-0 p-0 pt-2">
                    <h6 class="m-0">End Date</h6>
                  </div>
                </div>
                <div
                  class="row m-0 col-12 p-0"
                  v-for="(item, index) in js_certifications"
                  :key="index"
                >
                  <div class="col-3 p-0">
                    <h6 class="m-0 Main_Blue bold pt-3">
                      {{ item.certificateName }}
                    </h6>
                  </div>
                  <div class="col-3 d-block mb-8 mt-3 p-0">
                    <p>{{ item.awardedBy }}</p>
                  </div>
                  <div class="col-3 p-0 pt-3">
                    <p>{{ item.startingDate }}</p>
                  </div>
                  <div class="col-3 p-0 pt-3">
                    <p>{{ item.endDate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="bg-white p-0 d-none">
            <div class="py-4 px-4 border_b_silver col-12">
              <span class="card-label font-weight-bolder Main_Blue"
                >Download your profile
              </span>
            </div>
            <div class="col-12 p-4 cv_col_left_ch">
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim
              </p>
              <div class="btn_don_cv">Download PDF</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- <Footer /> -->
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <!-- <arrow-up-icon class="icons"></arrow-up-icon> -->
    </a>
    <!-- Back to top -->
  </div>
</template>
